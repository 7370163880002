<template>
  <div class="app-container">
    <!-- form表单 -->
    <!-- form表单 -->
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div>
        <el-row style="width:85%!important;">
          <el-col :span="8">
            <el-form-item :label="$t('title.Platformsite')">
              <!-- <el-radio-group v-model="siteCode" @change="PlatformsiteChange">
              <el-radio label="">{{ $t("title.OrderAll") }}</el-radio>
              <el-radio v-for="item in terraceData" :key="item.id" v-model="siteCode" class="mr-3" :label="item.siteCode">{{ item.siteName }}</el-radio>
            </el-radio-group> -->
              <el-select v-model="form.siteCode" multiple value-key="id" clearable filterable @change="PlatformsiteChange">
                <el-option v-for="item in terraceData" :key="item.id" :label="item.siteCode" :value="item.siteCode" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('title.PlatformAccount')">
              <!-- <el-radio-group v-model="form.account" @change="PlatformAccountChange">
              <el-radio label="">{{ $t("title.OrderAll") }}</el-radio>
              <el-radio v-for="(item,index) in PlatformAccountList" :key="index" class="mr-3" :label="item.accountName">{{ item.accountName }}</el-radio>
            </el-radio-group> -->
              <el-select v-model="form.account" multiple value-key="id" clearable filterable @change="PlatformAccountChange">
                <el-option label="全部" value="">全部</el-option>
                <el-option v-for="item in PlatformAccountList" :key="item.id" :label="item.accountName" :value="item.accountName" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="标签">
              <el-cascader v-model="form.labelsId" :options="tagData" clearable :props="props">
                <template slot-scope="{ data }">
                  <span>{{ data.labelName }}</span>
                  <span> ({{ data.labelCount }}) </span>
                </template>
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 标签 -->
        <el-row />
        <el-row type="flex" justify="space-between">
          <div style="width: 85%">
            <el-row class="Row-Input" :class="{ 'show-row': showRow }">
              <!-- 消息id -->
              <el-col :span="8">
                <el-form-item label="消息ID">
                  <el-input v-model="form.messageId" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <!-- 平台订单号 -->
              <el-col :span="8">
                <el-form-item :label="$t('title.platNumber')">
                  <el-input v-model="form.orderCoder" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <!-- 客户id -->
              <el-col :span="8">
                <el-form-item label="客户ID">
                  <el-input v-model="form.messageSender" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <!-- BU -->
              <el-col :span="8">
                <el-form-item label="BU">
                  <el-input v-model="form.bu" placeholder="请选择" />
                </el-form-item>
              </el-col>
              <!-- style -->
              <el-col :span="8">
                <el-form-item label="style">
                  <el-select v-model="form.style" value-key="id" clearable filterable @change="styleSelectChange">
                    <el-option v-for="item in styleOptions" :key="item.id" :label="item.styleName" :value="item.styleName" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- color -->
              <el-col :span="8">
                <el-form-item label="color">
                  <el-select v-model="form.color" clearable filterable>
                    <el-option v-for="item in colorOptions" :key="item.id" :label="item.colorName" :value="item.colorName" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- size -->
              <el-col :span="8">
                <el-form-item label="size">
                  <el-select v-model="form.size" clearable filterable>
                    <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 产品等级 -->
              <el-col :span="8">
                <el-form-item label="产品等级">
                  <el-select v-model="form.position" placeholder="请选择" clearable>
                    <el-option value="P3" label="P3" />
                    <el-option value="P2" label="P2" />
                    <el-option value="P1" label="P1" />
                    <el-option value="P0" label="P0" />
                    <el-option value="新品" label="新品" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 关键字 -->
              <el-col :span="8">
                <el-form-item label="关键字">
                  <el-input v-model="form.messageKey" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <!-- 平台sku -->
              <el-col :span="8">
                <el-form-item :label="$t('title.PlatformSku')">
                  <el-input v-model="form.sku" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item :label="$t('title.shipmentnumber')">
                  <el-input v-model="form.trackingId" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('title.Finalrespondent')">
                  <el-input v-model="form.lastReplyName" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :label="$t('title.time')">
                  <el-date-picker v-model="timerange" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row style="width: 15%">
            <el-col class="row-center">
              <el-button type="primary" :loading="tableLoading" @click="_getebaypagedlist(0,true)">{{ $t("page.search") }}</el-button>
              <el-button @click="resetQuery">{{ $t("page.reset") }}</el-button>
              <el-button type="text" @click="showRowClick()">{{
                showRow ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-row>
        <el-row :span="24" style="margin:5px 0;">
          <el-col :span="24" style="display: flex; height: 36px">
            <el-button size="small" @click="handleHandle(5)">标记已处理</el-button>
            <el-button size="small" @click="handleHandle(6)">标记未处理</el-button>
            <el-button type="primary" size="small" @click="handleDialogVisibleLable = true">
              标签
            </el-button>
            <el-button v-if="form.messageStatus == 5" type="primary" size="small" @click="noticeVisible = true">
              提醒
            </el-button>
            <el-button type="primary" size="small" @click="exportEmail">
              导出
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <table-select-action :quantity="multipleSelection.length" @reset="handleTableSelectReset" />
    <!-- tab切换 -->
    <el-tabs v-model="form.messageStatus" @tab-click="_getebaypagedlist(0)">
      <el-tab-pane v-for="item in StatusGroup" :key="item.messageStatus" :name="item.messageStatus + ''">
        <span slot="label">{{ item.messageStatusName }}
          <span class="count ml-3" style="color: red">({{ item.messageCount }})</span></span>
        <BuyerTable ref="table" :form="form" :table-data="tableData" :flag="true" :table-loading="tableLoading" :table-height="tableHeight" :disabled-flag="true" @getMul="getMul" @getthreadList="getthreadList" @refresh="_getebaypagedlist(0)" />
      </el-tab-pane>
    </el-tabs>
    <!-- 手工同步 -->
    <el-dialog title="同步日期" :visible.sync="PullmailbyhanddialogVisible" width="40%">
      <el-date-picker v-model="timeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="PullmailbyhanddialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="PullmailbyhandSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 标签 -->
    <el-dialog :visible.sync="handleDialogVisibleLable" width="40%">
      <el-tree ref="tree" class="my-4 menu-true" highlight-current node-key="id" :expand-on-click-node="false" :data="lableDatas" :props="defaultProps" show-checkbox />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="tagSure">确定</el-button>
        <el-button size="small" @click="addLabel">新建/管理标签</el-button>
      </span>
    </el-dialog>
    <!-- 新增管理标签 -->
    <el-dialog title="新建/管理标签" :visible.sync="handleDialogVisiblewRemind" width="50%" @close="closeTag">
      <el-tree :data="lableDatas" node-key="id" default-expand-all :props="defaultProps" :expand-on-click-node="false">
        <el-row slot-scope="{ node, data }" class="custom-tree-node">
          <el-col>{{ node.label }}</el-col>
          <el-col>
            <el-button type="text" size="mini" @click="() => edit(data)">
              编辑
            </el-button>
            <el-button v-if="data.parentId === -1" type="text" size="mini" @click="() => append(2, data)">
              添加二级标签
            </el-button>
            <el-button v-else-if="node.level===2" type="text" size="mini" @click="() => append(3, data)">
              添加三级标签
            </el-button>
            <el-button type="text" size="mini" @click="() => remove(node, data)">
              删除
            </el-button>
          </el-col>
        </el-row>
      </el-tree>
      <el-button v-if="show === 'button'" icon="el-icon-plus" style="width: 100%" class="mt-4" @click="show = 'input'">添加一级标签</el-button>
      <el-row v-if="show === 'input'" :gutter="20" class="mt-3">
        <el-col :span="15">
          <el-input ref="labelInput" v-model="labelName" />
        </el-col>

        <el-col :span="3">
          <el-button @click="addSure(labelName, labelLevel, parentId, id)">{{
            $t("page.sure")
          }}</el-button>
        </el-col>
        <el-col :span="3">
          <el-button @click="addCancel">{{
            $t("title.cancel")
          }}</el-button>
        </el-col>
      </el-row>
      <div style="text-align: right; margin-top: 18px">
        <el-button @click="handleDialogVisiblewRemind = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 提醒 -->
    <el-dialog :visible.sync="noticeVisible" width="25%">
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(1)">
        <span>明天 </span><span>中午12:00</span>
      </p>
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(2)">
        <span>后天 </span><span>中午12:00</span>
      </p>
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(3)">
        <span>三天内</span><span>中午12:00</span>
      </p>
      <p style="display: flex; justify-content: space-between;cursor:pointer;" @click="handleRemind(7)">
        <span>七天内</span><span>中午12:00</span>
      </p>
      <p @click="defineNoticeTimeVisible = true"><span>自定义</span></p>
      <p @click="handleNotice()">
        <span style="cursor:pointer;">查看 </span><span class="count">( {{ warnCount }} )</span>
      </p>
    </el-dialog>
    <!-- 自定义提醒时间设置 -->
    <el-dialog title="自定义日期" :visible.sync="defineNoticeTimedialogVisible" width="40%">
      <el-date-picker v-model="value1" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="defineNoticeTimedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="defineNoticeTimeSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-pagination :current-page="pager.current" :page-sizes="[20, 50, 100, 200, 300]" :page-size="pager.size" layout="total, sizes, prev, pager, next, jumper" :total="pager.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
import {
  messagesDeleteLabel,
  messagesUpdateLabel,
  messagesSaveLabel,
  batchUpdateMessageWarnById,
  messagesGetLabel,
  querySiteList,
  getAmazonPagedList,
  getAmazonMessageStatus,
  updatemessagestatusbylist,
  updatemessageisread,
  gettemplatelistbywhere,
  listAllAccount,
  sendmessagelist,
  batchMarkLabelInsert,
  selectMakeLabel,
  getmailormessageinfobyhand,
  getWarnMessageCount,
  batchAmazonUpdateMessageStatus
} from '@/api/service-management'
import mixin from '@/mixin/oms-mixin'
import { queryStyleList, queryColorList, querySizeList } from '@/api/omsorder'
import BuyerTable from '../Buyer-message/components/BuyerTable'
import TableSelectAction from '@/components/TableSelectAction'
import { deepClone } from '@/utils'
import store from '@/store'
const appData = require('@/views/service-manage/emoji.json')
export default {
  components: {
    TableSelectAction,
    BuyerTable
  },
  mixins: [mixin],
  data() {
    return {
      props: {
        checkStrictly: true,
        value: 'id',
        label: 'labelName',
        children: 'childNodes'
      },
      tagData: [],
      threadList: [],
      checkNotice: false,
      defineNoticeTimedialogVisible: false,
      warnCount: '',
      noticeVisible: false,
      value1: '',
      arrList: [],
      expandedKeys: [],
      id: '',
      sure: false,
      labelName: '',
      labelLevel: 1,
      parentId: -1,
      show: 'button',
      timeArr: [],
      definetimedialogVisible: false,
      PullmailbyhanddialogVisible: false,
      handleDialogVisibleLable: false,
      handleDialogVisiblewRemind: false,
      sendmessagelist: [],
      faceList: [],
      faceShow: false,
      typetableData: [],
      typeData: [],
      content: '',
      handleBatchreplydialogVisible: false,
      MessageStatus: '',
      StatusGroup: [],
      Allstatus: [],
      siteCode: 'US',
      flag: true,
      tableHeight: '',
      type: '',
      tableLoading: true,
      tableData: [],
      lableDatas: [],
      treeConfig: {
        children: 'childNodes'
      },
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      dialogVisible: false,
      showRow: false,
      terraceData: [],
      PlatformAccountList: [],
      form: {
        account: '',
        platform: 'EBAY',
        messageType: 0,
        messageStatus: '6',
        labelsId: ''
      },
      labelsId: '',
      multipleSelection: [],
      basicInfo: {},
      noteList: [],
      timerange: [],
      messageIDList: [],
      handleBatchreplyList: [],
      index: '',
      defaultProps: {
        children: 'childNodes',
        label: 'labelName'
      },
      styleOptions: [],
      colorOptions: [],
      sizeOptions: []
    }
  },
  computed: {
    getebaypagedlistParams() {
      this.getLabel()
      let isWarn = ''
      this.checkNotice ? (isWarn = 1) : ''
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      const {
        siteCode,
        account,
        messageId,
        orderCoder,
        messageSender,
        bu,
        FnSku,
        trackingId,
        lastReplyName,
        style,
        color,
        size,
        sku,
        position,
        messageKey,
        messageStatus
      } = this.form
      return Object.assign(
        {},
        {
          siteCode: siteCode ? siteCode.toString() : '',
          account: account.includes('') ? '' : account.toString(),
          messageId,
          orderCoder,
          messageSender,
          bu,
          FnSku,
          trackingId,
          lastReplyName,
          style,
          color,
          size,
          sku,
          position,
          messageKey,
          messageStatus,
          platform: 'EBAY'
        },
        this.pager,
        {
          lastModifyTimeStart,
          lastModifyTimeEnd,
          messageType: 0,
          isWarn,
          labelsId: this.labelsId
        }
      )
    },
    getmessageallstatuscountbytypeParams() {
      let isWarn = ''
      this.checkNotice ? (isWarn = 1) : ''
      const {
        siteCode,
        account,
        messageId,
        orderCoder,
        messageSender,
        bu,
        FnSku,
        trackingId,
        lastReplyName,
        style,
        color,
        size,
        sku,
        position,
        messageKey
      } = this.form
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      return Object.assign(
        {},
        { lastModifyTimeStart, lastModifyTimeEnd, platform: 'EBAY' },
        {
          siteCode: siteCode ? siteCode.toString() : '',
          account: account.includes('') ? '' : account.toString(),
          messageId,
          orderCoder,
          messageSender,
          bu,
          FnSku,
          trackingId,
          lastReplyName,
          style,
          color,
          size,
          sku,
          position,
          messageKey,
          labelsId: this.labelsId
        },
        { messageType: 0, isWarn }
      )
    },
    exportUrl() {
      this.getLabel()
      const obj = {}
      const {
        isAttachment,
        site,
        emailId,
        messageId,
        account,
        orderCoder,
        bu,
        style,
        color,
        size,
        sku,
        position,
        messageKey,
        messageStatus
      } = this.form
      const [lastModifyTimeStart, lastModifyTimeEnd] = this.timerange || []
      const OBJ = Object.assign(
        {},
        {
          isAttachment,
          site,
          emailId,
          messageId,
          account: account.includes('') ? '' : account.toString(),
          orderCoder,
          bu,
          style,
          color,
          size,
          sku,
          position,
          messageKey,
          labelsId: this.labelsId,
          messageStatus,
          messageType: 0
        },
        { lastModifyTimeStart, lastModifyTimeEnd, platform: 'EBAY' }
      )
      for (var key in OBJ) {
        OBJ[key] ? (obj[key] = OBJ[key]) : ''
      }
      console.log(obj)
      let url = ''
      for (var i in obj) {
        url = `${i}=${obj[i]}` + '&' + url
      }
      url = url.substr(0, url.length - 1)
      return process.env.VUE_APP_ERP_NEW_API + 'mos-web-core/Customer/exportMessageInfos?messageType=0&' + url
    }
  },
  created() {},
  mounted() {
    this.getType()
    this._selectMakeLabel()
    this._queryColorList()
    this._querySizeList()
    this._queryStyleList()
    // this._gettemplatelistbywhere()
    this._messagesGetLabel()
    this._querySiteList()
    this.getmailaccountinfo()
    this._getebaypagedlist(0)
  },
  methods: {
    closeTag() {
      this.show = 'button'
    },
    getLabel() {
      this.labelsId = this.form.labelsId
      this.labelsId = this.labelsId && this.labelsId[this.labelsId.length - 1]
    },
    async _selectMakeLabel() {
      const { datas } = await selectMakeLabel('EBAY', 0)
      this.tagData = datas
    },
    async tagSure() {
      if (!this.multipleSelection.length) {
        this.$message({
          type: 'warning',
          message: '请至少选择一条数据'
        })
        return
      }
      const arr = []
      let obj = {}
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (this.multipleSelection[i].orderList.length) {
          for (var j = 0; j < this.multipleSelection[i].orderList.length; j++) {
            obj.messageId = this.multipleSelection[i].id
            obj.orderId = this.multipleSelection[i].orderList[j].id
            obj.labelId = this.$refs.tree
              .getHalfCheckedKeys()
              .concat(this.$refs.tree.getCheckedKeys())
            arr.push(obj)
            obj = {}
          }
        } else {
          obj.messageId = this.multipleSelection[i].id
          obj.orderId = ''
          obj.labelId = this.$refs.tree
            .getHalfCheckedKeys()
            .concat(this.$refs.tree.getCheckedKeys())
          arr.push(obj)
          obj = {}
        }
      }
      console.log(arr)
      const { code, msg } = await batchMarkLabelInsert(true, arr)
      code === 0 && this.$message.success(msg)
      this.handleDialogVisibleLable = false
      this._getebaypagedlist(0)
    },
    getthreadList(val) {
      // console.log(val)
      this.threadList = val
    },
    async handleHandle(status) {
      if (this.threadList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      console.log(this.threadList)
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        const res = await batchAmazonUpdateMessageStatus(
          this.threadList,
          status
        )
        res.code === 0
          ? this.$message.success('操作成功')
          : this.$message.error('操作失败')
        this._getebaypagedlist(0)
      })
    },
    handleNotice() {
      this.checkNotice = true
      this.noticeVisible = false
      this._getebaypagedlist(0)
    },
    async defineNoticeTimeSure() {
      const { code, msg } = await batchUpdateMessageWarnById(
        this.threadList,
        1,
        this.value1
      )
      code === 0 && this.$message.success(msg)
      this.definetimedialogVisible = false
      this.value1 = ''
    },
    exportEmail() {
      window.open(this.exportUrl, '_blank')
    },
    async _getWarnMessageCount() {
      const { datas } = await getWarnMessageCount('EBAY', 1)
      this.warnCount = datas
    },
    async _getmailallstatuscountbytype() {
      const { datas } = await getAmazonMessageStatus(
        this.getmessageallstatuscountbytypeParams
      )
      this.StatusGroup = datas
      this.noticeVisible = false
    },
    unique(arr) {
      return Array.from(new Set(arr))
    },
    handleCheckChange() {},
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 选择style 带出color
    styleSelectChange(value) {
      const data = this.styleOptions.find((item) => item.styleName === value)
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // 获取所有标签
    async _messagesGetLabel() {
      const { datas } = await messagesGetLabel()
      this.lableDatas = datas
      console.log(this.lableDatas)
    },
    handleNodeClick(data) {
      console.log(data)
    },
    // 新增
    insertEvent() {
      const xTree = this.$refs.xTree
      const newRow = {
        labelName: '一级标签',
        labelLevel: 1,
        parentId: -1
      }
      xTree.insert(newRow).then(({ row }) => xTree.setActiveRow(row))
    },
    async remove(node, data) {
      console.log(node, data)
      const { code } = await messagesDeleteLabel(data.id)
      code === 0
        ? this.$message.success('删除成功')
        : this.$message.error('删除失败')
      this.show = 'button'
      this._messagesGetLabel()
    },
    append(level, data) {
      this.show = 'input'
      this.parentId = data.id
      this.labelLevel = level
    },
    edit(data) {
      this.sure = true
      // console.log(node, data);
      this.show = 'input'
      this.labelName = data.labelName
      this.id = data.id
      console.log(this.$refs.labelInput)
    },
    addCancel() {
      this.show = 'button'
    },
    // 新增tag
    async addSure(labelName, labelLevel, parentId, id) {
      // console.log(labelName, labelLevel, parentId, id);
      if (this.sure) {
        const { code } = await messagesUpdateLabel(id, this.labelName)
        code === 0
          ? this.$message.success('修改成功')
          : this.$message.error('修改失败')
      } else {
        const { code } = await messagesSaveLabel({
          labelName,
          labelLevel,
          parentId
        })
        code === 0
          ? this.$message.success('修改成功')
          : this.$message.error('修改失败')
      }
      this._messagesGetLabel()

      this.labelName = ''
      this.labelLevel = 1
      this.parentId = -1
      this.show = 'button'
      this.sure = false
    },
    resetQuery() {
      this.form = this.$options.data().form
      this.timerange = []
      this.timeArr = []
      this.checkNotice = false
      this._getebaypagedlist(0)
    },
    getIndex(val) {
      this.index = val
    },
    async PullmailbyhandSure() {
      const [startTime, endTime] = this.timeArr || []
      const { account } = this.form
      const { status } = await getmailormessageinfobyhand(
        'Ebay',
        account,
        startTime,
        endTime
      )
      status === 200
        ? this.$message.success('拉取邮件成功')
        : this.$message.error('拉取邮件失败')
      this.PullmailbyhanddialogVisible = false
    },
    getBrow(index) {
      for (const i in this.faceList) {
        if (index + '' === i) {
          this.getBrowString = this.faceList[index]
          this.content += this.getBrowString
        }
      }
    },
    faceContent() {
      this.faceShow = !this.faceShow
      if (this.faceShow === true) {
        for (const i in appData) {
          this.faceList.push(appData[i].char)
        }
      } else {
        this.faceList = []
      }
    },
    copyText(value) {
      var input = document.createElement('input')
      input.value = value
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$message.success('复制成功')
    },
    // async getType() {
    //   const { data } = await gettemplatetypelistbywhere({
    //     platform: 'Ebay'
    //   })
    //   this.typeData = data
    // },
    async _gettemplatelistbywhere() {
      const { data } = await gettemplatelistbywhere({
        type: this.type,
        platform: 'Ebay'
      })
      this.typetableData = data
    },
    keyDown() {},
    // 批量设置已读未读
    getupdatemessageisread(status) {
      if (this.messageIDList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        const res = await updatemessageisread(
          status,
          store.getters.name,
          this.messageIDList
        )
        res.status === 200
          ? this.$message.success('批量操作成功')
          : this.$message.error('批量操作失败')
        this._getebaypagedlist(0)
      })
    },
    async _updatemessagestatusbylist(status) {
      if (this.messageIDList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        const res = await updatemessagestatusbylist(
          status,
          store.getters.name,
          this.messageIDList
        )
        res.status === 200
          ? this.$message.success('批量操作成功')
          : this.$message.error('批量操作失败')
        this._getebaypagedlist(0)
      })
    },
    // 列表数据默认查询
    async _getebaypagedlist(type, notice) {
      try {
        if (notice && notice === true) {
          this.checkNotice = false
        }
        type === 0 ? (this.pager.current = 1) : ''
        this.tableLoading = true
        const {
          datas: { pager, records }
        } = await getAmazonPagedList(this.getebaypagedlistParams)

        const arr = deepClone(records)
        const sku = []
        const style = []
        const bu = []
        let SKU = []
        let BU = []
        let STYLE = []
        for (var i = 0; i < arr.length; i++) {
          arr[i].orderList.map((item) => {
            BU.push(item.bu)
            STYLE.push(item.style)
            SKU.push(item.sku)
          })
          sku.push(SKU)
          style.push(STYLE)
          bu.push(BU)
          SKU = []
          STYLE = []
          BU = []
        }
        // console.log(bu)
        for (var j = 0; j < sku.length; j++) {
          for (var k = 0; k < arr.length; k++) {
            arr[k].sku = this.unique(sku[k]).toString()
          }
        }
        for (let j = 0; j < style.length; j++) {
          for (let k = 0; k < arr.length; k++) {
            arr[k].style = this.unique(style[k]).toString()
          }
        }
        for (let j = 0; j < bu.length; j++) {
          for (let k = 0; k < arr.length; k++) {
            arr[k].bu = this.unique(bu[k]).toString()
          }
        }
        console.log(arr)
        this.tableData = arr
        this.tableLoading = false
        this.tableData.forEach((ele, index) => {
          // 遍历ele对象 添加index属性
          ele.index = index
        })
        this.pager = pager
        this._getmailallstatuscountbytype()
        this._getWarnMessageCount()
        this._selectMakeLabel()
      } finally {
        this.tableLoading = false
      }
    },
    //  列表分页方法
    handleSizeChange(val) {
      this.pager.size = val
      this._getebaypagedlist(0)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getebaypagedlist(1)
    },
    // 子传父
    getMul(data) {
      this.multipleSelection = data
      this.messageIDList = this.multipleSelection.map((item) => item.messageID)
      this.handleBatchreplyList = this.multipleSelection.map(
        (item) => item.senderID
      )
    },
    PlatformsiteChange(val) {
      this.siteCode = val
      this._getebaypagedlist(0)
    },
    PlatformAccountChange(val) {
      this.form.account = val
      this._getebaypagedlist(0)
    },
    showRowClick() {
      this.showRow = !this.showRow
    },
    async _querySiteList() {
      const { datas } = await querySiteList({ sellPlatformId: 8 })
      this.terraceData = datas
    },
    async getmailaccountinfo() {
      const { datas } = await listAllAccount()
      this.PlatformAccountList = datas
    },
    handleTableSelectReset() {
      this.multipleSelection = []
      console.log(this.multipleSelection)
      for (var i = 0; i < this.$refs.table.length; i++) {
        this.$refs.table[i].$refs.table.clearSelection()
      }
    },
    async handlesendmessageList() {
      this.content = this.content.replace(/<[^>]+>/g, '').trim()
      if (this.content === '') return this.$message.error('请输入内容')
      this.sendmessagelist = []
      for (var i = 0; i < this.multipleSelection.length; i++) {
        const {
          account,
          id,
          messageId,
          messageStatus,
          ebayMessageInfoList
        } = this.multipleSelection[i]
        const obj = Object.assign(
          {},
          {
            account,
            serviceMessageId: id,
            messageId,
            messageStatus,
            senderId: ebayMessageInfoList[0].recipientId,
            senderType: 2,
            subject: ebayMessageInfoList[0].subject,
            recipientId: ebayMessageInfoList[0].senderId
          },
          { body: this.content }
        )
        this.sendmessagelist.push(obj)
      }
      const { code } = await sendmessagelist(this.sendmessagelist)
      code === 200 && this.$message.success('发送成功')

      this.faceShow = false
      this.handleBatchreplydialogVisible = false
      this._getebaypagedlist(0)
    },
    // 新增/管理标签
    addLabel() {
      this._messagesGetLabel()
      this.handleDialogVisiblewRemind = true
    },
    setDate(n, type) {
      var day3 = new Date()
      day3.setTime(day3.getTime() + n * 24 * 60 * 60 * 1000)
      var s3 =
        day3.getFullYear() + '-' + (day3.getMonth() + 1) + '-' + day3.getDate()
      if (type === 0) {
        return s3
      } else {
        return s3 + ' 12:00:00'
      }
    },
    async handleRemind(type) {
      if (this.threadList.length === 0) {
        this.$message.error(this.$t('title.select'))
        return
      }
      type &&
        (type === 1
          ? (this.warnTime = this.setDate(1, 1))
          : type === 2
            ? (this.warnTime = this.setDate(2, 1))
            : type === 3
              ? (this.warnTime = this.setDate(3, 1))
              : type === 7
                ? (this.warnTime = this.setDate(7, 1))
                : '')
      console.log(this.warnTime)
      const { code, msg } = await batchUpdateMessageWarnById(
        this.threadList,
        1,
        this.warnTime
      )
      code === 0 && this.$message.success(msg)
      this._getebaypagedlist(0)
      this.noticeVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
.browBox {
  position: absolute;
  bottom: -150px;
  width: 100%;
  height: 150px;
  background: #e6e6e6;
  overflow: scroll;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
    li {
      width: 10%;
      font-size: 20px;
      list-style: none;
      text-align: center;
    }
  }
}
.tableTab {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #f4f4f5;
  span {
    padding: 0 50px;
  }
}
/deep/.el-textarea__inner {
  height: 150px;
  overflow: auto;
}
.faceicon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
}
/deep/.el-date-editor {
  width: 100%;
  .el-range-separator {
    padding: 0;
  }
}
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
/deep/ .el-form-item {
  margin-bottom: 3px;
}
/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
